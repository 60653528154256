import logo from './logo.svg';
import "bootstrap/dist/css/bootstrap.min.css";
import {Routes,Route,Link} from "react-router-dom";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import './App.css';
import Home from "./components/home";
import Login from "./components/login";
import AuthUser from './components/AuthUser';
import React, {useEffect, useState, useRef} from "react";
import {Icon, addIcon, addCollection} from '@iconify/react/dist/offline';
import ReactDOM from 'react-dom';

import plus from '@iconify-icons/bi/plus';
import moonFill from "@iconify-icons/bi/moon-fill";
import robot from "@iconify-icons/bi/robot";
import bookmarkStar from "@iconify-icons/bi/bookmark-star";
import person from "@iconify-icons/bi/person";
import sendCheck from "@iconify-icons/bi/send-check";
import pencilFill from "@iconify-icons/bi/pencil-fill";
import {AiOutlineSync} from "react-icons/ai";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboard, faCheck} from "@fortawesome/free-solid-svg-icons";

// Assign icon data to name "bell" used in first example
addIcon('plus', plus);
addIcon('moonFill', moonFill);
addIcon('robot', robot);
addIcon('star', bookmarkStar);
addIcon('person', person);
addIcon('message', sendCheck);
addIcon('pencilFill', pencilFill);
addIcon('pencilFill', pencilFill);

function App() {

    const navigate = useNavigate()
    const [response, setResponse] = useState([])
    const [loader, setLoader] = useState(false)
    const [count, setCount] = React.useState(0);
    const [title, setTitle] = useState("")
    const [responsecount, setResponseCount] = useState("")
    const [copiedtitle, setCopiedTitle] = useState(false);
    const [copiedcontent, setCopiedContent] = useState(false);
    const textareaRef = useRef(null);

    const copyToClipboardTitle = (text) => {
        navigator.clipboard.writeText(text).then(
            function () {
                setCopiedTitle(true);
                setTimeout(() => setCopiedTitle(false), 1000);
            },
            function (err) {
                console.error("Could not copy text: ", err);
            }
        );
    };

    const copyToClipboardContent = (text) => {
        navigator.clipboard.writeText(text).then(
            function () {
                setCopiedContent(true);
                setTimeout(() => setCopiedContent(false), 1000);
            },
            function (err) {
                console.error("Could not copy text: ", err);
            }
        );
    };


    const handleClick = () => {
        setTitle(response.title)
        textareaRef.current.focus();
    };


    const createResponse = async (e) => {
        setLoader(true)
        e.preventDefault()
        const formData = new FormData()
        formData.append('title', title)


        await axios.post("https://bloggptbackend.adrightly.com/api/write/generate/", formData)
            .then(({data}) => {
                const countresponse = data.content.length;
                setResponseCount(countresponse);
                setResponse(data)

            })
            .catch((error) => {
                console.log(error)
            })
        setLoader(false)
    }

    // <div>
    //
    // </div>

    const {getToken,logout} = AuthUser();
    if (!getToken()){
        return <Login/>
    }
    const logoutUser = () =>{
        if (getToken != undefined){
            logout();
        }
    }
  return (
      <div>

          <div>
              <div className="main">
                  <ion-icon
                      id="toggle-menu"
                      className="header_toggle"
                      name="reorder-three-outline"
                  />

                  <ion-icon
                      id="toggle-menu"
                      className="header_toggle"
                      name="menu_outline"
                  />

                  <header className="nav" id="nav-menu">
                      <div className="nav_content">
                          <ion-icon
                              id="close-menu"
                              className="header_close"
                              name="close-outline"

                          />
                          <div className="newChat">
                              <div className="newChat_button">
                                  <button type="button" onClick={() => {
                                      setResponse([])
                                      setTitle('')
                                      setCount(0)
                                  }
                                  }>

                                      <Icon icon="plus"/>
                                      New Chat
                                  </button>
                              </div>
                          </div>
                          <div className="nav_items">
                              <span/>
                              <div className="nav_links">
                                  <ul>
                                      <li>
                                          <div className="nav_btns">
                                              <Link to="/">
                                                  <Icon icon="moonFill"/>
                                                  &nbsp; &nbsp; Dark Mode
                                              </Link>
                                          </div>
                                      </li>
                                      <li>
                                          <Link to="/">
                                              <Icon icon="robot"/>
                                              &nbsp; &nbsp; OpenAI
                                          </Link>
                                      </li>
                                      <li>
                                          <Link to="/">
                                              <Icon icon="star"/>
                                              &nbsp; &nbsp; Update
                                          </Link>
                                      </li>
                                      <li>

                                              <Icon icon="person"/>
                                          <Link onClick={logoutUser}>
                                              &nbsp; &nbsp; Logout
                                          </Link>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </header>

                  <div className="chat">
                      <div className="chat_main height-100">
                          <div className='main_section height-100'>
                              <div className="left_section height-100">
                                  <div className="chat_message height-100">
                                      <form className="info height-100">
                                        <textarea value={title} id="message" placeholder='Type here...' ref={textareaRef} onChange={(event) => {
                                            setTitle(event.target.value);
                                            {
                                                setCount(event.target.value.length)
                                            }
                                        }}>
                                        </textarea>
                                          <button id="field" className="edit-submit"
                                                  onClick={(event) => createResponse(event)}>
                                              <p style={{fontSize:20}}>{count}</p>
                                              {loader ? <AiOutlineSync/> : <Icon icon="message" className="edit-submit"/>}
                                          </button>
                                      </form>
                                  </div>
                                  {/*<div>*/}
                                  {/*    */}
                                  {/*</div>*/}
                              </div>
                              <div className="right_section">
                                  <div className="button_outer">
                                      <button className="button-6 " id="btn-bot" role="button"
                                              onClick={(event) => createResponse(event)}
                                              onChange={(event) => setTitle(response.title)}>Regenerate Response
                                      </button>
                                  </div>

                                  {response.content ? (
                                      <form className="info height-100">
                                          <div className='copy_to_clipboard_icon'  style={{whiteSpace:"pre-wrap"}}>
                                              <FontAwesomeIcon icon={faClipboard}
                                                               onClick={() => copyToClipboardContent(response.content)}/>


                                                  <div style={{float:"left",fontWeight:"bold",color:"rgb(142, 142, 160)"}}>
                                                      Total Response: &nbsp; &nbsp;
                                                      {
                                                          responsecount
                                                      }
                                                  </div>


                                              {copiedcontent && (
                                                  <p style={{float:"right"}}>
                                                      Copied to Clipboard
                                                  </p>
                                              )}

                                              {
                                                  response.content
                                              }

                                          </div>
                                      </form>

                                  ) : (
                                      <div className="chat_data">
                                          <div className="chat_col">
                                              <div className="cols cols_main">
                                                  <i className="uil uil-sun"/>
                                                  <h2>Examples</h2>
                                                  <li>
                                                      <Link to="/">
                                                          "Explain quantum computing in simple terms" →
                                                      </Link>
                                                  </li>
                                                  <li>
                                                      <Link to="/">
                                                          "Got any creative ideas for a 10 year old’s birthday?" →
                                                      </Link>
                                                  </li>
                                              </div>
                                              <div className="cols">
                                                  <i className="uil uil-bolt"/>
                                                  <h2>Capabilities</h2>
                                                  <li>Remembers what user said earlier in the conversation</li>
                                                  <li>Allows user to provide follow-up corrections</li>
                                              </div>
                                              <div className="cols">
                                                  <i className="uil uil-exclamation-triangle"/>
                                                  <h2>Limitations</h2>
                                                  <li>May occasionally generate incorrect information</li>
                                                  <li>
                                                      May occasionally produce harmful instructions or biased
                                                      content
                                                  </li>

                                              </div>
                                          </div>
                                      </div>
                                  )}
                                  <div
                                      className="chat_data"
                                  >
                                  </div>
                                  <div
                                      className="chat_data"
                                  >
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

  );
}

export default App;
