import {useState} from "react";
import AuthUser from "./AuthUser";
import App from "../App";
export default function Login() {
    const {http,setToken,getToken} = AuthUser();
    const [email,setEmail] = useState();
    const [password,setPassword] = useState();
    const submitForm = () =>{
        http.post('/login',{email:email,password:password}).then((res)=>{
            setToken(res.data.user,res.data.access_token);
        })
    }

    return(
        // <div>
        //     <div className="form-group mt-3">
        //         <label htmlFor="email">Email address:</label>
        //         <input type="email" className="form-control" name="email" placeholder="Enter email" onChange={e=>setEmail(e.target.value)} id="email"  />
        //     </div>
        //     <div className="form-group mt-3">
        //         <label htmlFor="pwd">Password:</label>
        //         <input type="password" className="form-control" name="password" placeholder="Enter password" onChange={e=>setPassword(e.target.value)} id="pwd"/>
        //     </div>
        //     <button type="button" onClick={submitForm} className="btn btn-primary mt-4">Submit</button>
        // </div>
    <div className="login-container">

        <form className="login-form">
            {/*{*/}
            {/*    response &&*/}
            {/*    <div style={{width:200,color:"white",background:"#388e3c",textAlign:"center"}}>*/}
            {/*        Login Failed*/}
            {/*    </div>*/}

            {/*}*/}


            <h1>Login</h1>
            <input type="email" id="email" placeholder="Email" name="email" onChange={e=>setEmail(e.target.value)}/>
            <input type="password" id="password" placeholder="Password" name="password"  onChange={e=>setPassword(e.target.value)} />
            <button type="button" onClick={submitForm} style={{position:"unset",width:100}}>Submit</button>
        </form>
    </div>
    )
}